import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import { useTranslation } from "react-i18next"
import { CurrentLanguage } from "../components/MenuItems"

export const AllPosts = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"

  const { EnPosts, MnPosts } = useStaticQuery(query)
  const enPosts = EnPosts?.nodes ?? undefined
  const mnPosts = MnPosts?.nodes ?? undefined

  const mnData = mnPosts
    ? mnPosts.map((item, index) => {
        const id = item?.id ?? undefined
        const titleMn = item?.title?.trim() ?? undefined
        const slugMn = item?.slug?.trim() ?? undefined
        const excerptMn = item?.excerpt?.trim() ?? undefined
        const contentRaw = item?.content?.markdown ?? undefined
        const contentMn = (
          <div className="px-4 prose-sm sm:prose">
            <ReactMarkdown children={contentRaw} />
          </div>
        )

        const tagsMn = item?.tags?.map(tag => tag)
        const authorPath = item?.author ?? undefined
        const authorNameMn = authorPath?.name
          ? authorPath?.name?.trim()
          : undefined
        const authorJobMn = authorPath?.job
          ? authorPath?.job?.trim()
          : undefined
        const blogTypeMn = item?.blogType?.type
          ? item?.blogType?.type?.trim()
          : undefined
        const pureId = id.split(":")[1]

        return {
          id,
          pureId,
          titleMn,
          slugMn,
          excerptMn,
          contentMn,
          tagsMn,
          authorNameMn,
          authorJobMn,
          blogTypeMn,
        }
      })
    : undefined

  const enData = enPosts
    ? enPosts.map((item, index) => {
        const id = item?.id ?? undefined
        const titleEn = item?.title?.trim() ?? undefined
        const coverImageSharp = item?.coverImage
          ? getImage(item.coverImage)
          : undefined
        const coverImage = coverImageSharp ? (
          <GatsbyImage
            image={coverImageSharp}
            alt={titleEn}
            className="w-full"
          />
        ) : undefined

        const slugEn = item?.slug?.trim() ?? undefined
        const date = item?.date ?? undefined
        const excerptEn = item?.excerpt?.trim() ?? undefined
        const contentEnRaw = item?.content?.markdown?.trim() ?? undefined
        const contentEn = (
          <div className="px-4 prose-sm sm:prose">
            <ReactMarkdown children={contentEnRaw} />
          </div>
        )

        const tagsEn = item?.tags?.map(tag => tag)
        const authorPath = item?.author ?? undefined
        const authorNameEn = authorPath?.name?.trim() ?? undefined
        const authorJobEn = authorPath?.job?.trim() ?? undefined
        const authorImageSharp = authorPath?.picture
          ? getImage(authorPath.picture)
          : undefined
        const authorImage = authorImageSharp ? (
          <GatsbyImage
            image={authorImageSharp}
            alt={authorNameEn}
            className="w-full"
          />
        ) : undefined

        const blogTypeEn = item?.blogType
          ? item.blogType.type?.trim()
          : undefined

        const pureId = id.split(":")[1]

        return {
          id,
          pureId,
          titleEn,
          coverImage,
          slugEn,
          date,
          excerptEn,
          contentEn,
          tagsEn,
          authorNameEn,
          authorImage,
          authorJobEn,
          blogTypeEn,
        }
      })
    : undefined

  const bigArray = mnData > enData ? mnData : enData
  const smallArray = mnData > enData ? enData : mnData

  const merged = []
  for (let i = 0; i < bigArray.length; i++) {
    merged.push({
      ...bigArray[i],
      ...smallArray.find(
        item => item.id.split(":")[1] === bigArray[i].id.split(":")[1]
      ),
    })
  }

  const arrangedByDateMerged = merged.sort(function (a, b) {
    if (a.date > b.date) {
      return -1
    }
    if (a.date < b.date) {
      return 1
    }
    return 0
  })

  const mergedData = arrangedByDateMerged
    .map((item, index) => {
      const authorImage = item?.authorImage ?? undefined
      const pureId = item?.pureId ?? undefined
      const author = mn ? item?.authorNameMn : item?.authorNameEn
      const authorJob = mn ? item?.authorJobMn : item?.authorJobEn

      const blogType = mn ? item?.blogTypeMn : item?.blogTypeEn
      const content = mn ? item?.contentMn : item?.contentEn
      const cover = item?.coverImage ?? undefined
      const date = item?.date ?? undefined
      const excerpt = mn ? item?.excerptMn : item?.excerptEn
      const slug = mn
        ? item?.slugMn
          ? item.slugMn
          : item?.slugEn
        : item?.slugEn
        ? item.slugEn
        : item?.slugMn
      const tag = mn ? item?.tagsMn : item?.tagsEn
      const title = mn
        ? item?.titleMn
          ? item?.titleMn
          : item?.titleEn
        : item?.titleEn
        ? item.titleEn
        : item?.titleMn

      return {
        pureId,
        index,
        title,
        author,
        authorImage,
        authorJob,
        blogType,
        content,
        cover,
        date,
        excerpt,
        slug,
        tag,
      }
    })
    .sort(function (a, b) {
      if (a.index < b.index) {
        return -1
      }
      if (a.index > b.index) {
        return 1
      }
      return 0
    })

  return mergedData
}

export const DefaultImage = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"

  const { DefaultImage } = useStaticQuery(query)
  const sharp = DefaultImage?.nodes
    ? getImage(DefaultImage.nodes[0]?.data?.Image?.localFiles[0])
    : undefined
  const image = sharp ? (
    <GatsbyImage image={sharp} alt="Default image" className="w-full" />
  ) : undefined
  return image
}

export const BlogHeroImage = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"

  const { BlogHeroImage } = useStaticQuery(query)
  const sharp = BlogHeroImage?.nodes
    ? getImage(BlogHeroImage.nodes[0]?.data?.Image?.localFiles[0])
    : undefined
  const image = sharp ? (
    <GatsbyImage image={sharp} alt="Default image" className="w-full" />
  ) : undefined
  return image
}

const query = graphql`
  {
    EnPosts: allGraphCmsPost(
      filter: { locale: { eq: en } }
      sort: { fields: date, order: ASC }
    ) {
      nodes {
        id
        title
        coverImage {
          gatsbyImageData(
            width: 1000
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
        slug
        date
        excerpt
        content {
          markdown
        }
        tags
        author {
          name
          picture {
            gatsbyImageData(width: 100, layout: CONSTRAINED)
          }
          job
        }
        blogType {
          type
        }
      }
    }
    MnPosts: allGraphCmsPost(
      filter: { locale: { eq: mn } }
      sort: { fields: date, order: ASC }
    ) {
      nodes {
        id
        title
        slug
        date
        excerpt
        content {
          markdown
        }
        tags
        author {
          name
          job
        }
        blogType {
          type
        }
      }
    }

    DefaultImage: allAirtable(
      filter: { id: { eq: "c20edc0f-7c45-58e0-84eb-3f1d35a0d5ce" } }
    ) {
      nodes {
        data {
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(width: 400, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    BlogHeroImage: allAirtable(
      filter: { id: { eq: "c7f43df2-58c0-52f7-b4ca-3b99d276cea5" } }
    ) {
      nodes {
        data {
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`
