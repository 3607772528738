import React from "react"
import CardPost from "../components/CardPost"
import Layout from "../components/layout"
import PageTitle from "../components/PageTitle"
import { AllPosts, BlogPage, BlogHeroImage } from "../graphql/GraphCMSPostQuery"
import PageHeader from "../templates/PageHeader"
import { BlogText } from "../graphql/QueryById"
import SEO from '../components/seo'

const Blog = () => {
  const posts = AllPosts()
  // console.log(posts)

  const contents = posts.map((item, index) => {
    return <div key={`Blog-${index}`}>{item.content}</div>
  })

  const cards = posts.map((item, index) => {
    return <CardPost key={index} data={item} />
  })

  return (
    <Layout>
      <SEO title={BlogText()} description='Шинэ мэдлэг, мэдээллүүд' />
      {/* <PageHeader
        title={pageData.title}
        text={pageData.subtitle}
        image={BlogHeroImage()}
        tall
      /> */}
      {/* <div className="flex justify-center my-20 text-3xl font-semibold text-gray-800">Блог & Нийтлэл</div> */}
      <PageTitle title={BlogText()} />

      <div className="relative z-20 grid max-w-screen-lg gap-10 mx-4 my-10 rounded md:mx-auto md:p-10 md:grid-cols-3 md:bg-white">
        {cards}
      </div>
    </Layout>
  )
}

export default Blog
