import React from "react"
import { Link } from "gatsby"
import Badge from "./Badge"
import { DefaultImage } from "../graphql/GraphCMSPostQuery"

const CardPost = ({ data, small, noModal, medium }) => {
  const {
    title,
    author,
    authorImage,
    blogType,
    cover,
    date,
    excerpt,
    tag,
    slug,
  } = data

 

  return (
    <>
      <Link
        to={`/blog/${slug}`}
        className="relative flex flex-col border rounded shadow-sm cursor-pointer sm:max-w-xs group hover:shadow-lg"
      >
        <div
          className={`relative flex object-cover overflow-hidden rounded-t filter group-hover:contrast-150 ${
            small ? "h-20" : medium ? "h-40 max-w-40" : "h-56"
          }`}
          style={{
            WebkitBackfaceVisibility: "hidden",
            MozBackfaceVisibility: "hidden",
            WebkitTransform: "translate3d(0, 0, 0)",
            MozTransform: "translate3d(0, 0, 0)",
          }}
        >
          {cover || DefaultImage()}
          <div className="absolute inset-0 w-full h-full">&nbsp;</div>
          <div className="absolute inset-0 hidden transition group-hover:flex "></div>
        </div>
        <div
          className={`p-2  text-center text-gray-700 rounded-b group-hover:text-gray-900 md:text-sm font-semibold items-center flex justify-center flex-1 bg-gray-50 ${
            small ? "font-normal" : "font-semibold"
          }`}
        >
          {title}
        </div>
      </Link>
    </>
  )
}

export default CardPost
